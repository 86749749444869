# Run this example by adding <%= javascript_pack_tag 'hello_coffee' %> to the head of your layout file,
# like app/views/layouts/application.html.erb.

console.log 'Hello world from coffeescript'

#class @Twitter
#  eventsBound = false
#  @load: ->
#    Twitter.loadTwitterSDK()
#    Twitter.bindEvents() unless Twitter.eventsBound
#  @bindEvents: ->
#    if typeof Turbolinks isnt 'undefined' and Turbolinks.supported
#      $(document).on('page:load', Twitter.renderTweetButtons)
#    Twitter.eventsBound = true
#  @renderTweetButtons: ->
#    $('.twitter-share-button').each ->
#      button = $(this)
#      button.attr('data-url', document.location.href) unless button.data('url')?
#      button.attr('data-text', document.title) unless button.data('text')?
#    twttr.widgets.load()
#  @loadTwitterSDK: ->
#    $.getScript("https://platform.twitter.com/widgets.js")
#
#
##$(document).ready(Twitter.load())
##$(document).on('page:load', Twitter.load())
#jQuery(document).on('turbolinks:load', Twitter.load())
#jQuery(document).on('ready', Twitter.load())
